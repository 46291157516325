import { Container, Row, Col, Stack, Button } from 'react-bootstrap';
import { GeoAlt } from 'react-bootstrap-icons';
import React, { useState, useEffect } from 'react';
import { Telephone, EnvelopeAt } from 'react-bootstrap-icons';
import img from "./photos/a.png";
import img2 from "./photos/a2.png";

function Header() {
    const [width, setWidth] = useState(window.innerWidth);

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        handleWindowSizeChange();
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    const scrollToContact = () => {
        const element = document.getElementById('contact');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    return (
        <div className='header-section mb-5 d-flex align-items-center justify-content-end' style={{ height: '70vh', position: 'relative' }}>
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: -1,
                    backgroundImage: `url(${width < 1200 ? img2 : img})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    filter: 'brightness(0.5)'
                }}
            ></div>
            <Container>
                <Row className='w-100'>
                    <Col md={6} xs={0}></Col>
                    <Col className='align-content-center my-5'>
                        <Stack className="text-start justify-content-center">
                            <h1 style={{ fontFamily: '"Oswald", sans-serif', fontSize: '4rem', color: 'white', filter: 'brightness(140%)' }}>
                                PRANIE TAPICERKI
                            </h1>
                            <span className="mb-3" style={{ color: 'white' }}>z dojazdem do Ciebie.</span>
                            <h3 className="mb-3" style={{ color: '#FEB941' }}>
                                <GeoAlt className='me-2 mb-1' style={{ color: '#FEB941' }} />
                                Żagań - Szprotawa - Małomice
                            </h3>
                            <p className="mb-3" style={{ color: 'white' }}>
                                Chcesz odświeżyć swoje zabrudzone meble? Oferujemy profesjonalne pranie i czyszczenie tapicerki samochodowej oraz meblowej.
                                <br /> Usługi wykonujemy z dojazdem do Ciebie.
                            </p>
                            <Button variant="info" onClick={scrollToContact}><Telephone className='me-1 mb-1' /><a style={{ color: 'black' }} href="tel:786-661-612">786 661 612</a></Button>
                        </Stack>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Header;