import { useEffect, useState } from 'react';
import $ from "jquery";
import { Container, Row, Col, Card, Stack } from 'react-bootstrap';

function Photos() {

  const [furniture, setFurniture] = useState([]);
  const [cars, setCars] = useState([]);
  const [carpets, setCarpets] = useState([]);
  const [ajaxRequestsSent, setAjaxRequestsSent] = useState(false);
    
  useEffect(() => {
    if (!ajaxRequestsSent){
      $.ajax({
        type: "GET",
        url: "server/api.php?action=getFurniture",
        data: "",
        success: (data) => {
            try{
                setFurniture((JSON.parse(data)).reverse());       
            }
            catch(err){
                console.log(err);
            } 
        }
    });
    $.ajax({
        type: "GET",
        url: "server/api.php?action=getCars",
        data: "",
        success: (data) => {
            try{
                setCars((JSON.parse(data)).reverse());        
            }
            catch(err){
                console.log(err);
            }  
        },
    });
    $.ajax({
        type: "GET",
        url: "server/api.php?action=getCarpets",
        data: "",
        success: (data) => {
            try{
                setCarpets((JSON.parse(data)).reverse());      
            }
            catch(err){
                console.log(err);
            }
        },
    });
    setAjaxRequestsSent(true);
    }

  }, [ajaxRequestsSent])
    
    return (
      <>
        <div style={{backgroundColor:'rgb(0,0,0,0.07)'}}>
            <Container className='pb-4' style={{display:'block'}}>   
                <h2 className='pt-2' style={{color:'#6DC5D1', fontWeight:'bold' }}>Tapicerka Meblowa</h2>
                <p>Pranie tapicerki meblowej odświeża wnętrza, przywracając meblom pierwotną czystość i urok.</p>
                <Row className='row row-cols-1 row-cols-md-2 g-2'> 
                    {furniture.map((index, idx) => 
                        <Col xs={12} md={4} key={idx}>
                            <Card>
                                <Stack direction="horizontal">
                                    <img src={index.path} width={'50%'} style={{borderTopLeftRadius:'5px'}} />
                                    <img src={index.path2} width={'50%'} style={{borderTopRightRadius:'5px'}}/>
                                </Stack>
                                <Card.Footer>
                                    <small>{index.date}</small>
                                </Card.Footer>
                            </Card>
                        </Col>
                    )}     
                </Row>
                <h2 className='pt-2' style={{color:'#6DC5D1', fontWeight:'bold' }}>Tapicerka Samochodowa</h2>
                <p>Pranie tapicerki samochodowej zapewnia szybkie i skuteczne usunięcie zabrudzeń, przywracając świeży wygląd wnętrza pojazdu.</p>
                <Row className='row row-cols-1 row-cols-md-2 g-2'> 
                    {cars.map((index, idx) => 
                        <Col xs={12} md={4} key={idx}>
                            <Card >
                                <Stack direction="horizontal">
                                    <img src={index.path} width={'50%'} style={{borderTopLeftRadius:'5px'}} />
                                    <img src={index.path2} width={'50%'}style={{borderTopRightRadius:'5px'}}/>
                                </Stack>
                                <Card.Footer>
                                    <small>{index.date}</small>
                                </Card.Footer>
                            </Card>
                        </Col>
                    )}     
                </Row>
                <h2 className='pt-2' style={{color:'#6DC5D1', fontWeight:'bold' }}>Materace i Dywany</h2>
                <p>Pranie materacy i dywanów odświeża wnętrza, eliminując niechciane zabrudzenia i zapachy.</p>
                <Row className='row row-cols-1 row-cols-md-2 g-2'> 
                    {carpets.map((index, idx) => 
                        <Col xs={12} md={4} key={idx}>
                            <Card >
                                <Stack direction="horizontal">
                                    <img src={index.path} width={'50%'} style={{borderTopLeftRadius:'5px'}} />
                                    <img src={index.path2} width={'50%'} style={{borderTopRightRadius:'5px'}}/>
                                </Stack>
                                <Card.Footer>
                                    <small>{index.date}</small> 
                                </Card.Footer>
                            </Card>
                        </Col>
                    )}     
                </Row>
            </Container>
        </div>
      </>
        
    );
};

export default Photos;



