import { Container, Button } from 'react-bootstrap';
import { FacebookProvider, EmbeddedPost } from 'react-facebook';
import FlickityCarousel from './FlickityCarousel';
import { MdReviews } from 'react-icons/md';
import '../index.css';

const Reviews = () => {

  const posts = [
    "https://www.facebook.com/permalink.php?story_fbid=pfbid0Fv5WZuJb5wcZN2MkGwqK4ACCnKHugj8nWqNBRvN7a7Anpwx1RR1tXnf9bwDMgpa7l&id=100003073997988",
    "https://www.facebook.com/permalink.php?story_fbid=pfbid0xpLq2RrYL1MmqCH2xzRoX5NdZSdFQ6GU67jHkrQpATvnnPNLZmWSk2RTyzbDULrZl&id=100010088291900",
    "https://www.facebook.com/paula.kostiikostka/posts/pfbid02K5SYpxc2PR6TwBAJoHnZ1hhMK1LwsibeBegLVxfmxtZQQQAmhxQfTSoMQLhDX9nyl",
    "https://www.facebook.com/anna.rymarz/posts/pfbid033jbYGQ8CbrXFHbaT435HMy9ngwJdVGFpvfuHhCF4fyJiuztDFLRD6vGUcyxv1T77l",
    "https://www.facebook.com/arkadiusz.zatwarnicki/posts/pfbid02uB27q3YUW1WY2sd5YL8h8uxCkuVXjWAfzPsAXrDxj2rCWqCgftAbazKGFZFrRphsl",
    "https://www.facebook.com/danuta.skutrys/posts/pfbid035NVvVHMFWp3g1NL7hsuVpvgp7nijXv1fMEAvv31Y6JsrYg2jzbTnPG4efEhE4zNql",
    "https://www.facebook.com/dominika.danielak.16/posts/pfbid02mRWcCs47L9DiZxSvSfkuJXyhQikNbXDhrLCVDEdZdcJsHitGQUVGRx58P9xmLRsol",
    "https://www.facebook.com/agata.gajewska.14/posts/pfbid0MrK1n84pM4FunVppBAMqn8QCNjQRAYSh8BDVryNbhTWHyGN6k7P41MPVZsEw7RaPl",
    "https://www.facebook.com/ania.brodzinska.18/posts/pfbid0Kzr9fTmUzPzEU8LWcVr2EGeKM4tMVdXiriqKq1LrLHv7m15giCRvzZicFZYRTmRgl",
    "https://www.facebook.com/magdalena.bota.9/posts/pfbid02YNaPRQdGe9ved98aWjCV8ajdb61zDyFrWKSrnq7R4W9AKquZuWiTaMvGGGgTJCzil",
    "https://www.facebook.com/permalink.php?story_fbid=pfbid043wx8JZGTzcAKukjyR6EieuiKdbgZSiiZPcmBTdccVwxm4QkLmVG5Hgpp453YHBql&id=100008142975506",
    "https://www.facebook.com/weronika.lozia/posts/pfbid021yGbDu33MCKb5vgSw5NhJd1nJkXKQPqFdS8ADDML57EazRdA8xSNUUFJWDNUQiDrl",
    "https://www.facebook.com/permalink.php?story_fbid=pfbid035EdtMveaWYhow9NDYFFQP2nZzR26VeF2UBsEEagkixSK9CtLKqxYxJj2NzUu92f4l&id=100005161477098",
    "https://www.facebook.com/Kamila.jankowiak890/posts/pfbid027EqeJxnSvYjx1tEZ4jWd4vWPmYswRzCNZjcEwMnmzrFzVHFcAKQaZ1yQmG9NQxp7l",
    "https://www.facebook.com/sylwia.subocz/posts/pfbid02AiSaQKfWXw2pb1WC3M4gzSKn87pDbgq4JryR3QhadvsUV9cnideUVyqZR8hH1cTgl"
  ];

  return (
    <div className='my-5' style={{height: '500px'}}>
      <Container>
        <span style={{ color: '#FEB941' }}>CO LUDZIE O NAS MYŚLĄ?</span>
        <h2 style={{ color: '#6DC5D1', fontWeight:'bold' }}>Opinie Klientów</h2>
        <Button href="https://www.facebook.com/profile.php?id=100067156426175&sk=reviews&locale=pl_PL" variant="outline-info">
          Twoja opinia jest dla nas ważna - Podziel się swoimi uwagami <MdReviews className='ms-2' />
        </Button>
        <p className="mt-3 mb-5">Jesteśmy dumni z naszych 5-gwiazdkowych recenzji na Facebook. Sprawdź, co mówią o nas nasi klienci.</p>
      </Container>
      <FlickityCarousel>
        {posts.map((post, index) => (
          <div key={index} id="reviews" style={{ position: 'relative', width: 400, height: 'auto'}}>
            <FacebookProvider appId="YOUR_FACEBOOK_APP_ID">
              <EmbeddedPost href={post} width={400} showText />
            </FacebookProvider>
            <div style={{ position: 'absolute', top: 0, left: 0, width: '110%', height: '100%', backgroundColor: 'transparent', pointerEvents: 'auto' }}></div>
          </div>
        ))}
      </FlickityCarousel>
    </div>
  );
}

export default Reviews;