import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navi from "./components/Navbar";
import Header from "./components/Header";
import GalleryM from "./components/GalleryM";
import Descriptions from "./components/Descriptions";
import Footer from "./components/Footer";
import Contact from "./components/Contact";
import Admin from "./components/Admin";
import Photos from "./components/Photos";
import Reviews from "./components/Reviews";
import {Helmet} from "react-helmet";

function App() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Czyszczenie tapicerki</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        <meta name="description" content="Czyszczenie tapicerki" />
      </Helmet>

      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Navi />
                <Header />
                <Descriptions />
                <GalleryM />
                {/* <Contact /> */}
                <Reviews />
                <Footer />
              </>
            }
          />
          <Route
            path="/photos"
            element={
              <>
                <Navi />
                <Photos />
                <Footer />
              </>
            }
          />
          <Route
            path="/upload"
            element={<Admin />}
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;