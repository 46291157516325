import React, { useState, useEffect } from 'react';
import { Container, Form, Row, Col, Card, Stack, Button } from 'react-bootstrap';
import $ from "jquery";
import { XSquare } from "react-bootstrap-icons";

const Admin = () => {
    const [furniture, setFurniture] = useState([]);
    const [cars, setCars] = useState([]);
    const [carpets, setCarpets] = useState([]);
    const [auth, setAuth] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = $(e.target);
        $.ajax({
            type: "POST",
            url: form.attr("action"),
            data: form.serialize() ,
            success: (data) => {     
                if (data.trim() != 'error'){
                    try{
                        localStorage.setItem("token", data.trim());
                        window.location.reload();
                    }
                    catch(err){
                        console.log(err);
                    }  
                } else console.log("Nieprawidłowe hasło.");
            },
        });
    };

    useEffect(() => {
        var userToken = localStorage.getItem('token');
        $.ajax({
            type: "POST",
            url: "server/api.php?action=auth",
            data: {userToken: userToken},
            success: (data) => {    
                if (data.trim() == 'success'){
                    try{
                        setAuth(true);
                        handleClick(); 
                    }
                    catch(err){
                        console.log(err);
                    }
                }
            },
        });
    }, [])
    
    const handleClick = () => {
        $.ajax({
            type: "GET",
            url: "server/api.php?action=getFurniture",
            data: "",
            success: (data) => {
                try{
                    setFurniture((JSON.parse(data)).reverse())              
                }
                catch(err){
                    console.log(err);
                } 
            }
        });
        $.ajax({
            type: "GET",
            url: "server/api.php?action=getCars",
            data: "",
            success: (data) => {
                try{
                    setCars((JSON.parse(data)).reverse());        
                }
                catch(err){
                    console.log(err);
                }  
            },
        });
        $.ajax({
            type: "GET",
            url: "server/api.php?action=getCarpets",
            data: "",
            success: (data) => {
                try{
                    setCarpets((JSON.parse(data)).reverse());     
                }
                catch(err){
                    console.log(err);
                }
            },
        });
    };

    return (
        <Container>
            <Form action="server/api.php?action=login" method='POST' className="d-flex align-items-start my-4" style={{width: '50%'}} onSubmit={handleSubmit}>
                <Form.Group>
                    <Form.Control type="password" name="name" placeholder='password'/>
                </Form.Group>   
                <Button type="submit" className="ms-2" >Zaloguj</Button>
            </Form>
            { (auth == true) ? <Results furniture={furniture} cars={cars} carpets={carpets}/>  : null}
        </Container>
    );
};

const Results = ({furniture, cars, carpets}) => {

    const del = (table, element) => {
        $.ajax({
            type: "POST",
            url: "server/api.php?action=delete",
            data: {table: table, id: element.id},
            success: (data) => {
                console.log(data);
                window.location.reload();
            },
        });
    }

    const logout = () => {
        localStorage.removeItem('token');
        window.location.reload();
    }

    return(
        <>
            <div style={{height: '5vh'}} ></div>
            <Button variant='warning' className='w-50 mb-5' onClick={logout}>Wyloguj</Button>

            <Form action="server/api.php?action=upload" method='POST' encType="multipart/form-data" style={{width: '100%'}}>
                <Form.Group className="mb-3">
                    <Form.Label>Zdjęcie czystego</Form.Label>
                    <Form.Control type="file" name="clean" className="mb-2"/>

                    <Form.Label>Zdjęcie brudnego</Form.Label>
                    <Form.Control type="file" name="dirt" className="mb-2"/>

                    <Form.Label>Data wykonania</Form.Label>
                    <Form.Control type="date" name="dat" className="mb-2"/>

                    <Form.Label>Kategoria</Form.Label>
                    <Form.Select name='category' className="mb-2">
                        <option>-</option>
                        <option value="1">Meble</option>
                        <option value="2">Auta</option>
                        <option value="3">Materace, dywany</option>
                    </Form.Select>   
                    
                </Form.Group>
                <Button type="submit" name='submit' className="mb-2" style={{width:'100%'}}>Dodaj</Button>
            </Form>

            <div style={{backgroundColor:'rgb(0,0,0,0.25)'}}>
                <Container>
                    <div style={{height:'3vh'}}></div>
                    <h1>Tapicerka meblowa</h1>
                    <Row className='row row-cols-1 row-cols-md-2 g-2'> 
                        {furniture.map((index, idx) => 
                            <Col xs={12} md={4} key={idx}>
                                <Card >
                                    <Stack direction="horizontal">
                                        <img src={index.path} width={'50%'} style={{borderTopLeftRadius:'5px'}}/>
                                        <img src={index.path2} width={'50%'} style={{borderTopRightRadius:'5px'}}/>
                                    </Stack>
                                    <Card.Footer className="d-flex justify-content-between">
                                        <small>{index.date}</small>
                                        <XSquare onClick={() => del("furniture", index)}/>
                                    </Card.Footer>
                                </Card>
                            </Col>
                        )}     
                    </Row>
                    <h1>Tapicerka samochodowa</h1>
                    <Row className='row row-cols-1 row-cols-md-2 g-2'> 
                        {cars.map((index, idx) => 
                            <Col xs={12} md={4} key={idx}>
                                <Card >
                                    <Stack direction="horizontal">
                                        <img src={index.path} width={'50%'} style={{borderTopLeftRadius:'5px'}}/>
                                        <img src={index.path2} width={'50%'} style={{borderTopRightRadius:'5px'}}/>
                                    </Stack>
                                    <Card.Footer className="d-flex justify-content-between">
                                        <small>{index.date}</small>
                                        <XSquare onClick={() => del("cars", index)}/>
                                    </Card.Footer>
                                </Card>
                            </Col>
                        )}     
                    </Row>
                    <h1>Materace i dywany</h1>
                    <Row className='row row-cols-1 row-cols-md-2 g-2'> 
                        {carpets.map((index, idx) => 
                            <Col xs={12} md={4} key={idx}>
                                <Card >
                                    <Stack direction="horizontal">
                                        <img src={index.path} width={'50%'} style={{borderTopLeftRadius:'5px'}}/>
                                        <img src={index.path2} width={'50%'} style={{borderTopRightRadius:'5px'}}/>
                                    </Stack>
                                    <Card.Footer className="d-flex justify-content-between">
                                        <small>{index.date}</small> 
                                        <XSquare onClick={() => del("carpets", index)}/>
                                    </Card.Footer>
                                </Card>
                            </Col>
                        )}     
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default Admin;



