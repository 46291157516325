import React, { useEffect, useRef } from 'react';
import Flickity from 'flickity';

const FlickityCarousel = ({ children }) => {
  const carouselRef = useRef(null);

  useEffect(() => {
    const flickity = new Flickity('.carousel', {
        wrapAround: true,
        prevNextButtons: false,
        pageDots: false
    });

    return () => {
      flickity.destroy();
    };
  }, []);

  const childrenWithMargin = React.Children.map(children, child => (
    <div style={{ margin: '0 0.5rem' }}>{child}</div>
  ));

  return <div ref={carouselRef} className="carousel">{childrenWithMargin}</div>;
};

export default FlickityCarousel;