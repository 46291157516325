import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Stack, Card, ListGroup } from 'react-bootstrap';
import { MdOutlinePhoneInTalk, MdOutlineAlternateEmail, MdOutlineFacebook } from 'react-icons/md';
import { RiMailSendLine } from 'react-icons/ri';
import $ from 'jquery';
import img from './photos/man.jpg';
import img2 from './photos/man2.jpg';
import img3 from './photos/man3.jpg';

function Contact() {
    const [result, setResult] = useState("");
    const [validated, setValidated] = useState(false);
    const [style, setStyle] = useState("green");
    const [width, setWidth] = useState(window.innerWidth);

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
        } else {
            setValidated(true);
            try {
                const response = await $.ajax({
                    type: 'POST',
                    url: 'server/send-email-php/send-email.php',
                    data: $(form).serialize(),
                });
                setResult(response);
                form.reset();
            } catch (error) {
                console.log(error);
                setStyle(prevStyle => prevStyle !== "red" ? "red" : "dark");
                setResult('* Spróbuj ponownie później');
            }
            setValidated(false);
        }
    };

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        handleWindowSizeChange();
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    return (
        <div className='position-relative z-index-1'>
            <Container
                className='position-absolute z-index-n1 w-100 h-100'
                style={{
                    backgroundImage: `url(${ width < 767 ? img : width < 930 ? img2 : img})`,
                    backgroundSize: 'cover',
                    filter: 'brightness(0.5)',
                }}
                fluid
            ></Container>

            <Container className='mt-5 position-relative z-index-1 pt-4'>
                <span style={{ color: '#FEB941' }}>MASZ PYTANIA LUB CHESZ UMÓWIĆ SIĘ NA USŁUGĘ?</span>
                <h2 style={{ color: '#6DC5D1', fontWeight:'bold'  }}>Skontaktuj Się z Nami</h2>
                <p className="mt-3 mb-5" style={{ color: 'white' }}>Dla Ciebie jesteśmy dostępni codziennie w standardowych godzinach.</p>
            </Container>

            <Container style={{ position: 'relative' }} className='mb-3 p-3'>
                <Row>
                    <Col md={7} xs={12}>
                        <Form id="contact" onSubmit={handleSubmit} noValidate validated={validated}>
                            <Stack direction='horizontal'>
                                <Form.Group className="mb-2 me-2" style={{ width: '50%' }}>
                                    <Form.Label style={{ color: 'white' }}>Imię</Form.Label>
                                    <Form.Control type="text" name="name" style={{ border: '3px solid lightgray' }} required />
                                </Form.Group>
                                <Form.Group className="mb-2" style={{ width: '100%' }}>
                                    <Form.Label style={{ color: 'white' }}>E-mail</Form.Label>
                                    <Form.Control type="email" name="email" style={{ border: '3px solid lightgray' }} required />
                                </Form.Group>
                            </Stack>
                            <Form.Group className="mb-2">
                                <Form.Label style={{ color: 'white' }}>Temat</Form.Label>
                                <Form.Select name="subject" style={{ border: '3px solid lightgray' }} required>
                                    <option value="">Wybierz temat...</option>
                                    <option value="Tapicerka Meblowa">Tapicerka Meblowa</option>
                                    <option value="Tapicerka Samochodowa">Tapicerka Samochodowa</option>
                                    <option value="Materace i Dywany">Materace i Dywany</option>
                                    <option value="Inne">Inne</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-4">
                                <Form.Label style={{ color: 'white' }}>Wiadomość</Form.Label>
                                <Form.Control name="message" as="textarea" rows={2} style={{ border: '3px solid lightgray' }} required />
                            </Form.Group>
                            <Button className="mb-1" variant="outline-info" size="md" type="submit" style={{ width: '100%' }}>
                                Wyślij <RiMailSendLine className='ms-2 mb-1' />
                            </Button>
                        </Form>
                        <span style={{ color: style }} className='mx-4'>{result}</span>
                    </Col>

                    <Col md={5} className='d-flex align-items-center'>
                        <Card style={{ width: '100%', border: 'none', backgroundColor: '#6DC5D1' }}>
                            <Card.Header>
                                <div className="text-center my-2">
                                    <span style={{ color: 'rgb(0,0,0,0.3)' }}>NASZE DANE KONTAKTOWE</span>
                                </div>
                            </Card.Header>
                            <ListGroup variant="flush">
                                <ListGroup.Item style={{ backgroundColor: '#6DC5D1', border: 'none' }}>
                                    <Stack direction='horizontal' gap={3}>
                                        <MdOutlinePhoneInTalk size="2rem" style={{ color: 'white' }} className='ms-4 my-2' />
                                        <p style={{ color: 'white' }} className='m-0'>+ 48 786 661 612</p>
                                    </Stack>
                                </ListGroup.Item>
                                <ListGroup.Item style={{ backgroundColor: '#6DC5D1', border: 'none' }}>
                                    <Stack direction='horizontal' gap={3}>
                                        <MdOutlineAlternateEmail size="2rem" style={{ color: 'white' }} className='ms-4 my-2' />
                                        <p style={{ color: 'white' }} className='m-0'>cleanwash@wp.pl</p>
                                    </Stack>
                                </ListGroup.Item>
                                <ListGroup.Item style={{ backgroundColor: '#6DC5D1', border: 'none' }} className='mb-5'>
                                    <Stack direction='horizontal' gap={3}>
                                        <MdOutlineFacebook size="2rem" style={{ color: 'white' }} className='ms-4 my-2' />
                                        <a style={{ color: 'white' }} href='https://www.facebook.com/profile.php?id=100067156426175&locale=pl_PL'>
                                            CleanWash - Mobilne Pranie Tapicerki
                                        </a>
                                    </Stack>
                                </ListGroup.Item>
                            </ListGroup>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Contact;